<template>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate">
        <v-row dense>
            <v-col cols="12">
                <v-switch v-model="item.enable" label="Mostrar no mapa" dense />
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" md="6">
                <v-text-field v-model="item.name" :rules="[rules.required]" label="Nome" dense required />
            </v-col>
            <v-col cols="12" md="6">
                <v-text-field v-model="item.cnpj" :rules="[rules.cnpj]" label="CNPJ" v-mask="'##.###.###/####-##'" dense />
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12">
                <v-text-field type="tel" v-model="item.contact" :rules="[rules.phone]" label="Contato" dense />
            </v-col>
        </v-row>
        <v-row class="my-12">
            <v-col cols="12">
                <v-simple-table dense>
                    <thead>
                        <tr>
                            <th class="text-primary">Dia da Semana</th>
                            <th class="text-primary">Abre</th>
                            <th class="text-primary">Fecha</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(day, index) in daysOfWeek" :key="index">
                            <td>{{ day.description }}</td>
                            <td>
                                <v-text-field
                                    v-model="item.schedule[day.key].openOn"
                                    :rules="[rules.time]"
                                    v-mask="'##:##'"
                                    dense
                                    hide-details
                                />
                            </td>
                            <td>
                                <v-text-field
                                    v-model="item.schedule[day.key].closeOn"
                                    :rules="[rules.time]"
                                    v-mask="'##:##'"
                                    dense
                                    hide-details
                                />
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" md="6">
                <v-autocomplete v-model="item.idCity" :rules="[rules.required]" :items="cityList" item-value="id" item-text="name" label="Cidade" dense />
            </v-col>
            <v-col cols="12" md="6">
                <v-text-field v-model="item.cep" :rules="[rules.cep]" label="CEP" v-mask="'#####-###'" dense />
            </v-col>
            <v-col cols="12" md="9">
                <v-textarea v-model="item.address" label="Endereço" rows="2" dense />
            </v-col>
            <v-col cols="12" md="3">
                <v-text-field v-model="item.houseNumber" label="Número" type="number" dense />
            </v-col>
        </v-row>
        <v-row dense class="my-12">
            <v-col cols="12" md="6">
                <v-text-field v-model="item.location" :rules="[rules.required, rules.coordinate]" label="Coordenadas" dense required />
            </v-col>
            <v-col cols="12" md="6">
                <v-text-field v-model="item.timezone" :rules="[rules.timezone]" label="Timezone" v-mask="rules.timezoneMask" dense />
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12">
                <v-textarea v-model="item.description" label="Descrição" rows="2" dense />
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12">
                <v-btn block :loading="lock" :disabled="!valid" color="success" @click="validate">Salvar</v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";

export default {
    directives: { mask },

    props: {
        itemToEdit: {
            type: Object,
        },
        lock: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            rules,
            valid: true,
            cityList: [],
            daysOfWeek: [
                { key: "Monday", description: "Segunda-feira" },
                { key: "Tuesday", description: "Terça-feira" },
                { key: "Wednesday", description: "Quarta-feira" },
                { key: "Thursday", description: "Quinta-feira" },
                { key: "Friday", description: "Sexta-feira" },
                { key: "Saturday", description: "Sábado" },
                { key: "Sunday", description: "Domingo" },
            ],
            itemClean: {
                name: "",
                idCity: "",
                schedule: {
                    Monday: { openOn: "", closeOn: "" },
                    Tuesday: { openOn: "", closeOn: "" },
                    Wednesday: { openOn: "", closeOn: "" },
                    Thursday: { openOn: "", closeOn: "" },
                    Friday: { openOn: "", closeOn: "" },
                    Saturday: { openOn: "", closeOn: "" },
                    Sunday: { openOn: "", closeOn: "" },
                },
            },
            item: { ...this.itemClean },
        };
    },

    mounted() {
        this.$http
            .get("api/v2/city?sort[name]=ASC")
            .then((result) => {
                this.cityList = result;
            })
            .catch(() => {
                this.cityList = [];
            });
    },

    watch: {
        itemToEdit: {
            immediate: true,
            deep: true,
            handler(newVal) {
                if (this.$refs.form) {
                    this.$refs.form.reset();
                }

                if (newVal == null) {
                    this.item = { ...this.itemClean };
                    this.$nextTick(function () {
                        this.item = { ...this.itemClean };
                    });
                } else {
                    this.item = { ...newVal };
                    this.$nextTick(function () {
                        this.item = { ...newVal };
                    });
                }
            },
        },
    },

    methods: {
        validate() {
            if (this.$refs.form.validate()) {
                this.$emit("save", this.item);
            }
        },
    },
};
</script>